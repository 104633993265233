<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>
<style lang="less">
@import "./assets/css/custom_font.less";
#app {
  height: 100%;
}
.ant-table-tbody {
  > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #fafafa !important;
  }
}
.ant-table-fixed {
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #fafafa !important;
  }
}
.ant-calendar-time-picker-btn:hover {
  color: white;
}
.ant-calendar-time-picker-btn {
  color: #fff;
  background-color: #00253e;
  border-color: #00253e;

  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 24px;
}
.ant-calendar-time-picker-btn-disabled {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  text-shadow: none;
  box-shadow: none;

  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 24px;
}
</style>
