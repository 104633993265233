import Vue from "vue";
import VueRouter from "vue-router";
import BaseLayout from "@/layouts/BaseLayout";
import BlankLayout from "@/layouts/Blank";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BaseLayout,
    redirect: "/welcome",
    children: [
      {
        path: "/welcome",
        component: () => import("../views/welcome"),
        meta: {
          title: "欢迎进入管理系统",
          menuName: "/welcome"
        }
      },
      {
        path: "/users",
        component: () => import("../views/users/index"),
        meta: {
          title: "用户管理",
          menuName: "/users"
        }
      },
      {
        path: "/password",
        component: () => import("../views/pass/index"),
        meta: {
          title: "修改密码",
          menuName: "/password"
        }
      },
      {
        path: "/content",
        component: BlankLayout,
        meta: {
          title: "日常管理",
          menuName: "/content"
        },
        children: [
          {
            path: "/contentJewelry",
            component: () => import("../views/users/index"),
            meta: {
              title: "用户信息管理",
              menuName: "/contentJewelry"
            },
            hidden: false
          },
          {
            path: "/watchRegister",
            component: () => import("../views/watch/register"),
            meta: {
              title: "腕表登记管理",
              menuName: "/watchRegister"
            },
            hidden: false
          }
        ]
      },
      {
        path: "/banner",
        component: BlankLayout,
        meta: {
          title: "系统管理",
          menuName: "/banner"
        },
        children: [
          {
            path: "/watchMainData",
            component: () => import("../views/watch/mainData.vue"),
            meta: {
              title: "腕表主数据管理",
              menuName: "/watchMainData",
              type: 0
            },
            hidden: false
          },
          {
            path: "/bannerLevel",
            component: () => import("../views/banners/level.vue"),
            meta: {
              title: "轮播图管理",
              menuName: "/bannerLevel",
              type: 1
            },
            hidden: false
          },
          {
            path: "/admin",
            component: () => import("../views/admin/index.vue"),
            meta: {
              title: "账号管理",
              menuName: "/admin",
              type: 2
            },
            hidden: false
          }
        ]
      },
      {
        path: "/test",
        component: () => import("../views/test"),
        meta: {
          title: "/test"
        }
      },
      {
        path: "/help",
        component: () => import("../views/help/index"),
        meta: {
          title: "帮助",
          menuName: "/help"
        }
      },
      {
        path: "/watchBox",
        component: () => import("../views/watch/box"),
        meta: {
          title: "用户信息管理",
          menuName: "/watchBox"
        }
      }
    ]
  },
  {
    path: "/uesr",
    component: () => import("../layouts/UserLayout.vue"),
    children: [
      {
        path: "/user/login",
        name: "login",
        component: () => import("../views/user/login.vue")
      }
    ]
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach(async (to, from, next) => {
  let tokenInfo = localStorage.getItem("token");
  if (!tokenInfo) {
    // 判断token,登录信息
    if (to.path === "/user/login") {
      next();
    } else {
      next("/user/login");
    }
  } else {
    next();
  }
});
export default router;
